<template>
  <div class="todo-application">
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Filters</h5>
      </b-card-header>
      <b-card-body>
        <validation-observer
          #default="{ handleSubmit }"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <validation-provider
                #default="validationContext"
                name="date"
                rules="required"
              >
                <label>Date</label>
                <b-form-datepicker
                  v-model="date"
                  :state="getValidationState(validationContext)"
                  @input="$nextTick(() => handleSubmit(fetchOrders))"
                />
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-md-0 mb-2"
            >
              <validation-provider
                v-if="isAdmin"
                #default="validationContext"
                name="location"
                rules="required"
              >
                <label>Location</label>
                <location-picker
                  v-model="locationId"
                  :class="getValidationStateNonBootstrap(validationContext)"
                  :clearable="false"
                  @input="$nextTick(() => handleSubmit(fetchOrders))"
                />
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card-body>
    </b-card>
    <b-card
      no-body
    >
      <b-row
        class="h-100 todo-application"
        no-gutters
      >
        <b-col
          cols="12"
          md="4"
          class="border-right-light border-bottom-light content-area-wrapper"
        >
          <fulfillment-orders
            ref="orders"
            :date="date"
            :location-id="locationId"
            :is-admin="isAdmin"
            :order-count.sync="orderCount"
            @select="handleSelect"
          />
        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <div
            v-if="loading"
            class="d-flex justify-content-center align-items-center my-3"
          >
            <b-spinner
              variant="primary"
            />
          </div>
          <p
            v-else-if="orderCount > 0 && selectedOrder == null"
            class="text-center mt-3"
          >
            Please select an order to begin
          </p>
          <div
            v-else
            class="todo-app-list"
          >
            <vue-perfect-scrollbar
              :settings="perfectScrollbarSettings"
              class="todo-task-list-wrapper list-group scroll-area"
            >
              <ul
                class="todo-task-list media-list"
              >
                <li
                  v-for="orderItem in orderItems"
                  :key="orderItem.order_item_id"
                  :class="{ 'completed': orderItem.order_item_fulfilled_at }"
                  class="todo-item"
                >
                  <div class="d-flex justify-content-between">
                    <div class="d-flex">
                      <b-form-checkbox
                        :checked="orderItem.order_item_fulfilled_at != null"
                        :disabled="orderItem.order_item_fulfilled_at != null"
                        @input="fulfillItem(orderItem.order_item_id)"
                      />
                      {{ displayNameForItem(orderItem.item) }}
                    </div>

                    <div class="badge-wrapper mr-1">
                      <b-badge
                        pill
                        variant="light-primary"
                        class="text-capitalize"
                      >
                        Quantity {{ orderItem.order_item_quantity }}
                      </b-badge>
                    </div>
                  </div>
                </li>
              </ul>
            </vue-perfect-scrollbar>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      v-model="showPaletteModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-header
    >
      <validation-provider
        ref="paletteCountValidation"
        #default="validationContext"
        name="pallet count"
        rules="required|min_value:1"
      >
        <b-form-group label="Pallet Count">
          <b-form-input
            v-model="paletteCount"
            type="number"
            number
            min="0"
            :state="getValidationState(validationContext)"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <template v-slot:modal-footer>
        <spinner-button
          :loading="sendingPaletteCount"
          variant="primary"
          @click="submitPaletteCount()"
        >
          Submit
        </spinner-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BSpinner,
  BFormCheckbox,
  BFormDatepicker,
  BBadge,
  BModal,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import FulfillmentOrders from '@/views/fulfillment/FulfillmentOrders.vue'
import store from '@/store'
import fulfillmentStoreService from '@/services/fulfillmentStoreService'
import {
  ref, onUnmounted, computed,
} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
// eslint-disable-next-line import/no-cycle
import SessionService from '@/services/sessionService'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import moment from 'moment'
import { required } from '@core/utils/validations/validations'
import LocationPicker from '@/layouts/components/LocationPicker.vue'

export default {
  components: {
    LocationPicker,
    BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
    BSpinner,
    BFormCheckbox,
    BFormDatepicker,
    BBadge,
    BModal,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    FulfillmentOrders,

    VuePerfectScrollbar,
    SpinnerButton,

    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    required,
  }),
  setup() {
    // Store
    const FULFILLMENT_STORE_KEY = 'app-fulfillment'
    if (!store.hasModule(FULFILLMENT_STORE_KEY)) store.registerModule(FULFILLMENT_STORE_KEY, fulfillmentStoreService)

    onUnmounted(() => {
      if (store.hasModule(FULFILLMENT_STORE_KEY)) store.unregisterModule(FULFILLMENT_STORE_KEY)
    })

    // Variables
    const loading = ref(false)
    const sendingPaletteCount = ref(false)
    const orderItems = ref([])
    const date = ref(moment().format('YYYY-MM-DD'))
    const locationId = ref(null)
    const orders = ref(null)
    const selectedOrder = ref(null)
    const orderCount = ref(0)
    const paletteCount = ref(null)
    const showPaletteModal = ref(false)
    const paletteCountValidation = ref(null)

    const toast = useToast()

    // Computed
    const isAdmin = computed(() => SessionService.isAdmin())
    const orderComplete = computed(() => selectedOrder.value != null && orderItems.value.reduce((prev, next) => next.order_item_fulfilled_at != null, false))

    // Functions
    const fetchItems = orderId => {
      if (orderId != null) {
        store.dispatch(`${FULFILLMENT_STORE_KEY}/fetchItems`, { orderId })
          .then(response => {
            orderItems.value = response.data
          }).catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong when retrieving this order details please try again later',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        orderItems.value = []
      }
    }

    const handleSelect = order => {
      selectedOrder.value = order
      fetchItems(order?.order_id)
    }

    const fulfillItem = itemId => {
      store.dispatch(`${FULFILLMENT_STORE_KEY}/fulfillItem`, { orderId: selectedOrder.value.order_id, itemId })
        .then(response => {
          fetchItems(selectedOrder.value.order_id)

          if (response.data.order_complete) {
            showPaletteModal.value = true
          }
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong fulfilling this item please try again later',
              icon: 'AlterTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchOrders = () => {
      orders.value.fetch()
    }

    const displayNameForItem = item => (item.item_display_name != null && item.item_display_name.length > 0 ? `${item.item_name} (${item.item_display_name})` : item.item_name)

    const submitPaletteCount = () => {
      paletteCountValidation.value.validate().then(results => {
        if (results.valid) {
          store.dispatch(`${FULFILLMENT_STORE_KEY}/paletteCount`, { orderId: selectedOrder.value.order_id, paletteCount: paletteCount.value })
            .then(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Successfully saved your pallet count',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              fetchOrders()
              showPaletteModal.value = false
            }).catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong saving your pallet count please try again later',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    }

    // Vue Perfect Scrollbar Settings
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const {
      getValidationState, getValidationStateNonBootstrap,
    } = formValidation()

    return {
      loading,
      orderItems,
      date,
      locationId,
      isAdmin,
      orders,
      selectedOrder,
      orderComplete,
      orderCount,
      paletteCount,
      showPaletteModal,
      paletteCountValidation,
      sendingPaletteCount,

      handleSelect,
      fulfillItem,
      displayNameForItem,
      getValidationStateNonBootstrap,
      getValidationState,
      fetchOrders,
      submitPaletteCount,

      perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.todo-task-list-wrapper {
  position: relative;
  height: calc(100% - 3.56rem); // ? search box height (3.49rem) + 1px bottom border till 2 decimals
  background-color: $white;
  border-radius: 0;
  .todo-task-list {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      transition: all 0.2s, border-color 0s;
      position: relative;
      padding: 0.893rem 2rem;
      &:not(:first-child) {
        border-top: 1px solid $border-color;
      }
      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 3px 10px 0 $border-color;
        transition: all 0.2s;
      }
      .todo-title-wrapper {
        display: flex;
        justify-content: space-between;
      }
      .todo-title-area,
      .title-wrapper {
        display: flex;
        align-items: center;
      }
      // Todo Item Action
      .todo-item-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        a {
          font-size: 1.2rem;
          line-height: 1.5;
        }
      }

      .badge-wrapper {
        display: flex;
        .badge:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }
  }
  // When we search, no-results
  .no-results {
    display: none;
    padding: 1.5rem;
    text-align: center;
    &.show {
      display: block;
    }
  }
}
</style>
