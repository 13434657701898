<template>
  <div class="sidebar">
    <div class="todo-sidebar">
      <div class="todo-app-menu">
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center my-2"
        >
          <b-spinner
            variant="primary"
          />
        </div>
        <draggable
          v-if="orders.length > 0"
          v-model="orders"
          handle=".handle"
          class="list-group list-group-labels"
          @end="orderPriority"
        >
          <b-list-group-item
            v-for="(order) in orders"
            :key="order.order_id"
            :active="isOrderSelected(order.order_id)"
            :class="colorForStatus(order.order_fulfilled_at)"
            action
            @click="selectOrder(order)"
          >
            <div class="d-flex justify-content-between">
              <div>
                <span
                  v-if="isAdmin"
                  class="handle mr-1 cursor-move"
                >
                  <feather-icon icon="MenuIcon" />
                </span>
                {{ order.order_job_name }}
              </div>
              <div>
                {{ order.order_datetime | dateFormat }}
              </div>
            </div>
          </b-list-group-item>
        </draggable>
        <p
          v-else
          class="text-center mt-3"
        >
          No orders for this date
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BListGroupItem,
  BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import fulfillmentStoreService from '@/services/fulfillmentStoreService'
import {
  ref, onUnmounted,
} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
    BListGroupItem,
    BSpinner,
  },
  filters: {
    dateFormat: date => {
      if (date != null) {
        // It is recommended to not use moment-timezone anymore so this is how to format a date with JS :)
        const options = {
          hour: 'numeric',
          minute: '2-digit',
        }
        const epoch = Date.parse(`${date}+00:00`)
        const local = new Date(0)
        local.setUTCMilliseconds(epoch)
        return local.toLocaleTimeString([], options)
      }
      return ''
    },
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    locationId: {
      type: [Number, String],
      default: null,
    },
    isAdmin: {
      type: Boolean,
      required: true,
      default: false,
    },
    orderCount: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
  }),
  setup(props, { emit }) {
    // Store
    const FULFILLMENT_STORE_KEY = 'app-fulfillment'
    if (!store.hasModule(FULFILLMENT_STORE_KEY)) store.registerModule(FULFILLMENT_STORE_KEY, fulfillmentStoreService)

    onUnmounted(() => {
      if (store.hasModule(FULFILLMENT_STORE_KEY)) store.unregisterModule(FULFILLMENT_STORE_KEY)
    })

    // Variables
    const toast = useToast()

    const orders = ref([])
    const selected = ref(null)
    const loading = ref(false)
    const locations = ref([])

    // Functions
    const selectOrder = order => {
      selected.value = order

      emit('select', order)
    }

    const fetch = () => {
      loading.value = true
      selectOrder(null)
      store.dispatch(`${FULFILLMENT_STORE_KEY}/fetch`, props)
        .then(response => {
          orders.value = response.data
          emit('update:orderCount', orders.value.length)
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong fetching orders please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          loading.value = false
        })
    }

    const refreshOrders = () => {
      setTimeout(() => {
        if (props.locationId != null) {
          fetch()
        }

        refreshOrders()
      }, 300000)
    }

    const isOrderSelected = orderId => selected.value != null && +selected.value.order_id === +orderId

    const orderPriority = () => {
      // TODO: loop through and set priority and then send off to be updated.
      console.log(orders.value)
    }

    const colorForStatus = fulfilledAt => {
      if (fulfilledAt != null) {
        return 'completed'
      }
      return 'pending'
    }

    // Initial
    if (props.locationId != null) {
      fetch()
    }
    refreshOrders()

    return {
      orders,
      locations,
      loading,

      fetch,
      selectOrder,
      isOrderSelected,
      orderPriority,
      colorForStatus,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

.list-group .list-group-item {
  padding: 0.58rem 1.5rem;
  border: 0;
  font-weight: $font-weight-bold;
  letter-spacing: 0.4px;
  border-left: 2px solid transparent;
  border-radius: 0;
  + .list-group-item.active {
    margin-top: 0;
  }
  &:hover,
  &:focus,
  &.active {
    background: transparent;
  }
}

.pending {
  &.active {
    border-color: $warning !important;
    color: $warning !important;
  }
}

.completed {
  &.active {
    border-color: $success !important;
    color: $success !important;
  }
}

.completed:hover {
  border-color: $success !important;
  color: $success !important;
}

.pending:hover {
  border-color: $warning !important;
  color: $warning !important;
}
</style>
