import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetch(ctx, { date, locationId }) {
      return new Promise((resolve, reject) => {
        axios.get(`/fulfillment/orders?date=${date}&locationId=${locationId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItems(ctx, { orderId }) {
      return new Promise((resolve, reject) => {
        axios.get(`/fulfillment/orders/${orderId}/items`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fulfillItem(ctx, { orderId, itemId }) {
      return new Promise((resolve, reject) => {
        axios.post(`/fulfillment/orders/${orderId}/items/${itemId}/fulfill`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    paletteCount(ctx, { orderId, paletteCount }) {
      return new Promise((resolve, reject) => {
        axios.put(`/fulfillment/orders/${orderId}/palette-count`, { paletteCount })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
